import React, { useState, useEffect } from "react";
import { AllProducts } from "../../Components/AllProducts/AllProducts";
import { Header } from "../../Components/Header/Header";
import { CatagoryButtonGroup } from "../../Components/ButtonGroup/CatagoryButtonGroup";
// import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { prodcutType } from "../../Types/ProductTypes";
import { getAllProduct } from "../../Services/ProductService";
import { getProduct } from "../../Redux/Actions/ProductActions/ProductActions";

export const AllProductsPage = () => {
  const dispatch = useDispatch();
  const productData: Array<prodcutType> = useSelector(
    (data: any) => data.rootReducers.handleProduct
  );
  const [Products, setProducts] = useState<[] | Array<prodcutType>>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!productData.length) {
      getProducts();
    } else {
      setProducts(productData);
    }
    return () => {
      setProducts([]);
    };
  }, []);

  const getProducts = () => {
    setLoading(true);
    getAllProduct()
      .then(
        (res: any) => {
          const data = dispatch(getProduct(res.data));
          setProducts(data.payload);
          setLoading(false);
        },
        (err: any) => {
          console.log(err);

          setLoading(false);
        }
      )
      .catch((error) => {
        console.log(error);
        setLoading(false);
        throw error;
      });
  };

  return (
    <>
      <Grid container direction="column" gap={2} justifyContent={"center"}>
        <Grid xs={12} item mb={4}>
          <Header />
          <Grid item className="category_btn">
            <CatagoryButtonGroup />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AllProducts loading={loading} />
        </Grid>
      </Grid>
    </>
  );
};
