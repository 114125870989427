import React, { MouseEvent, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Badge, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import jwt_decode from "jwt-decode";
import { LoginModal } from "../Modal/LoginModal";
import { deleteJWT } from "../../Redux/Actions/AuthActions/AuthActions";

const pages = [
  { id: 1, name: "Products", path: "/product" },
  { id: 2, name: "Profile", path: "/profile" },
];
export const Header = (props: any) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((state: any) => state.rootReducers.handleCart);
  const authData = useSelector((state: any) => state.rootReducers.handleAuth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  // console.log(jwt_decode(authData.access_token)?.userName);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path?: string) => {
    setAnchorElNav(null);
    navigate(`${path}`);
  };

  const logout = () => {
    dispatch(deleteJWT());
    navigate("/");
  };

  return (
    <>
      <AppBar color="inherit" position="sticky" sx={{ marginBottom: 4 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <StoreMallDirectoryIcon
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />
            <Typography
              className="mainlogo"
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              NEW STORE
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                onClose={() => setAnchorElNav(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={() => handleCloseNavMenu("/product")}>
                  <Typography textAlign="center">Products</Typography>
                </MenuItem>
                {authData.access_token ? (
                  <MenuItem onClick={() => handleCloseNavMenu("/profile")}>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </Box>
            <StoreMallDirectoryIcon
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            />
            <Typography
              className="mainlogo"
              variant="h5"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              NEW STORE
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                onClick={() => handleCloseNavMenu("/product")}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                Products
              </Button>
              {authData.access_token ? (
                <Button
                  onClick={() => handleCloseNavMenu("/profile")}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  Profile
                </Button>
              ) : null}
            </Box>
            <Grid mr={2}>
              <IconButton color="inherit" onClick={() => navigate("/cart")}>
                <Badge badgeContent={cartData?.length} color="secondary">
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </IconButton>
            </Grid>
            {!authData.access_token ? (
              <Button
                color="inherit"
                onClick={() => setOpen(true)}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                Login/SignUp
              </Button>
            ) : (
              <>
                <Avatar
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  id="basic-button"
                  sx={{
                    bgcolor: deepPurple[500],
                    "&:hover": {
                      backgroundColor: deepPurple[400],
                      opacity: [0.9, 0.8, 0.7],
                      cursor: "pointer",
                    },
                  }}
                >
                  <IconButton onClick={(e) => handleClick(e)} color="inherit">
                    <PersonIcon />
                  </IconButton>
                </Avatar>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={() => handleClose()}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      logout();
                      handleClose();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <LoginModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};
