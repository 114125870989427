import { prodcutType } from "../../../Types/ProductTypes";

export const addCart = (product: prodcutType) => {
  return {
    type: "ADDITEM",
    payload: product,
  };
};
export const delCart = (product: prodcutType) => {
  return {
    type: "DELITEM",
    payload: product,
  };
};
