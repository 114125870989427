const jwt: object = {};

const handleAuth = (state = jwt, action: any) => {
  const payload = action.payload;
  switch (action.type) {
    case "SAVE_JWT":
      state = payload;
      const accessToken = JSON.stringify(payload);

      localStorage.setItem("JWT", accessToken);
      return state;
    case "REMOVE_JWT":
      state = { login: false };
      localStorage.removeItem("JWT");
      return state;
    default:
      return state;
  }
};

export default handleAuth;
