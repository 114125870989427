import React from "react";
import { Header } from "../../Components/Header/Header";
import { Profile } from "../../Components/Profile/Profile";

export const ProfilePage = () => {
  return (
    <>
      <Header />
      <Profile />
    </>
  );
};
