import { _post } from "./CommonServices";

export const registerUser = (data: any) => {
  const response = _post("auth/register", data);
  return response;
};

export const login = (data: any) => {
  const response = _post("auth/login", data);
  return response;
};
