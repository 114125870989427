import React, { useEffect, useState, MouseEvent } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getCatagory } from "../../Services/ProductService";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useDispatch } from "react-redux";
import { sortProduct } from "../../Redux/Actions/ProductActions/ProductActions";
import { Menu, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const buttons: Array<ReactJSXElement> = [];
export const CatagoryButtonGroup = () => {
  const dispatch = useDispatch();

  const [catagories, setCatagories] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const matches = useMediaQuery("(min-width:600px)");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    categoryGet();
  }, []);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const categoryGet = () => {
    getCatagory()
      .then(
        (res: any) => {
          setCatagories(res.data);
          if (!buttons.length && res.data.length) {
            res?.data.map((data: any) => {
              return buttons.push(
                <Button
                  key={data.id}
                  onClick={() => {
                    setAnchorElNav(null);
                    sort(data.id);
                  }}
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {data.catagory}
                </Button>
              );
            });
          } else {
            setCatagories([]);
          }
        },
        (err: any) => {
          console.log(err);
        }
      )
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  const sort = (data: string) => {
    dispatch(sortProduct(data));
  };

  return (
    <>
      {buttons.length ? (
        matches ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "none",
              "& > *": {
                m: 1,
              },
            }}
          >
            <ButtonGroup
              sx={{ marginLeft: 0, textTransform: "capitalize" }}
              color="inherit"
              size="small"
              aria-label="large  button group"
              variant="contained"
            >
              {buttons}
            </ButtonGroup>
          </Box>
        ) : (
          <>
            <Button
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              startIcon={<MenuIcon />}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Catagory
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              onClose={() => setAnchorElNav(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
            >
              <ButtonGroup
                orientation={"vertical"}
                sx={{ marginLeft: 0, textTransform: "capitalize" }}
                color="inherit"
                size="small"
                variant="text"
              >
                {buttons}
              </ButtonGroup>
            </Menu>
          </>
        )
      ) : null}
    </>
  );
};
