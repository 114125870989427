import { _get } from "./CommonServices";

export const getAllProduct = () => {
  const response = _get("products");
  return response;
};

export const getProductById = (id: string | undefined) => {
  const response = _get(`products/${id}`);
  return response;
};

export const getCatagory = () => {
  const response = _get("categories");
  return response;
};
