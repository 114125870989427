import React from "react";
import { Header } from "../../Components/Header/Header";
import { Product } from "../../Components/AllProducts/Product/Product";
import { Grid } from "@mui/material";
export const ProductPage = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Product />
      </Grid>
    </>
  );
};
