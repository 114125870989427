import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import Skeleton from "@mui/material/Skeleton";
import { Typography, Grid, Rating } from "@mui/material";
import { prodcutType } from "../../Types/ProductTypes";
import { useDispatch } from "react-redux";

const AutoPlaySwipeableViews = SwipeableViews;

interface Props {
  productData?: Array<prodcutType>;
  loading: boolean;
}

export const Gallery = (props: Props) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const Carousal = () => {
    return (
      <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          animateTransitions={true}
        >
          {props.productData?.map((data, index) => (
            <div key={data.id}>
              {Math.abs(activeStep - index) <= 2 ? (
                <>
                  <Grid
                    container
                    gap={4}
                    direction={{ md: "row", xs: "column" }}
                    xs={12}
                    item
                    wrap="nowrap"
                    alignItems={"center"}
                  >
                    <Grid item xs={5}>
                      <Box
                        component="img"
                        sx={{
                          height: {
                            xs: 200,
                            md: 500,
                          },
                          display: "block",
                          overflow: "hidden",
                          maxWidth: {
                            xs: "100%",
                            sm: "100%",
                            md: "50%",
                            lg: "50%",
                            xl: "50%",
                          },
                          objectFit: "contain",
                          margin: "0 auto",
                        }}
                        src={data.image}
                      />
                    </Grid>
                    <Grid item xs={7} p={{ xs: 2 }}>
                      <Typography variant="h4">Description</Typography>
                      <Typography variant="subtitle1">
                        {data.description}
                      </Typography>
                      <Rating value={data.rating_rate} readOnly />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
    );
  };

  const Loading = () => {
    return (
      <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          <div>
            <>
              <Grid
                container
                gap={4}
                direction={{ md: "row", xs: "column" }}
                xs={12}
                wrap="nowrap"
                alignItems={"center"}
                item
              >
                <Grid item xs={5}>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    sx={{
                      marginLeft: 2,
                      height: {
                        xs: 200,
                        md: 500,
                      },
                      width: {
                        xs: 200,
                        sm: 200,
                        md: 200,
                        lg: 200,
                        xl: 200,
                      },
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: {
                          xs: 200,
                          md: 500,
                        },
                        display: "block",
                        overflow: "hidden",
                        width: {
                          xs: 200,
                          sm: 200,
                          md: 200,
                          lg: 200,
                          xl: 200,
                        },
                        objectFit: "contain",
                      }}
                    />
                  </Skeleton>
                </Grid>
                <Grid item xs={7}>
                  <Skeleton variant="rounded">
                    <Typography variant="h4">Description</Typography>
                  </Skeleton>
                  <Skeleton variant="rounded" sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Ipsum, libero. Atque totam, minus facere, itaque nemo
                      placeat tenetur reiciendis culpa molestias inventore
                      adipisci fugiat nulla excepturi. Dolorem dignissimos
                      voluptate quia.
                    </Typography>
                  </Skeleton>
                  <Skeleton variant="rounded" sx={{ marginTop: 2 }}>
                    <Rating value={4} readOnly />
                  </Skeleton>
                </Grid>
              </Grid>
            </>
          </div>
        </AutoPlaySwipeableViews>
      </Box>
    );
  };

  return props.loading == false ? <Carousal /> : <Loading />;
};
