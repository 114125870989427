import React, { useEffect, useState } from "react";
import { Grid, Avatar, Typography } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

interface userData {
  Email: string;
  userName: string;
}
export const Profile = () => {
  const data: any = useSelector((state: any) => state.rootReducers.handleAuth);
  const [userData, setUserData] = useState<userData | any>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (data.login !== false) {
      const jwt: any = localStorage.getItem("JWT");
      if (jwt) {
        const userData: userData = jwtDecode(JSON.parse(jwt).access_token);
        setUserData(userData);
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Grid
        xs={12}
        item
        container
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Grid item xs={6}>
          <Avatar sx={{ bgcolor: deepPurple[100], width: 150, height: 150 }}>
            <PersonIcon sx={{ width: 100, height: 100 }} />
          </Avatar>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        mt={2}
      >
        <Grid container width={250}>
          <Grid container item xs={12} alignItems={"center"}>
            <Typography variant="subtitle1">Name:-</Typography>
            <Typography variant="subtitle2" ml={1}>
              {userData?.userName}
            </Typography>
          </Grid>
          <Grid container xs={12} alignItems={"center"} item>
            <Typography variant="subtitle1">Email:-</Typography>
            <Typography variant="subtitle2" ml={1}>
              {userData?.Email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
