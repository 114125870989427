import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCart, delCart } from "../../Redux/Actions/CartActions/CartActions";
import { prodcutType } from "../../Types/ProductTypes";
import { Grid, Typography, Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";

export const Cart = () => {
  const navigate = useNavigate();
  const cartData = useSelector((data: any) => data.rootReducers.handleCart);
  const dispatch = useDispatch();

  const removeItems = (productData: prodcutType) => {
    dispatch(delCart(productData));
  };

  const addItem = (productData: prodcutType) => {
    dispatch(addCart(productData));
  };
  let total = 0;

  const renderCart = (products: prodcutType, index: number) => {
    total =
      total + parseInt(products.price) * (products?.qty ? products.qty : 0);

    return (
      <>
        <Grid
          container
          xs={12}
          item
          key={index}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          padding={2}
        >
          <Grid item xs={6}>
            <Box
              component="img"
              src={products.image}
              sx={{
                height: 150,
                display: "block",
                overflow: "hidden",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item padding={2} xs={5}>
            <Typography variant="subtitle1">{products.title}</Typography>
            <Typography variant="subtitle2">
              {products?.qty}X ${products.price}=$
              {products.qty ? products.qty * parseInt(products.price) : null}
            </Typography>
            <IconButton color="error" onClick={() => removeItems(products)}>
              <RemoveIcon />
            </IconButton>
            {products?.qty}
            <IconButton color="success" onClick={() => addItem(products)}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  };

  const emptyCart = () => {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={100}
        alignContent={"center"}
      >
        <Typography variant="h3">Your Cart is empty!</Typography>
      </Grid>
    );
  };

  return (
    <>
      {!cartData.length && emptyCart()}
      {!cartData.length && (
        <Grid
          item
          xs={12}
          container
          padding={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item padding={2}>
            <Button
              variant="outlined"
              size="medium"
              color="success"
              onClick={() => navigate("/product")}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Continue Shopping
            </Button>
          </Grid>
        </Grid>
      )}
      {cartData.length ? (
        <>
          {cartData.map((cartData: prodcutType, index: number) =>
            renderCart(cartData, index)
          )}
          <Grid
            container
            padding={2}
            alignItems={"center"}
            justifyContent={"space-evenly"}
          >
            <Typography variant="subtitle1">Total</Typography>
            <Typography variant="subtitle2">${total}</Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            padding={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item padding={2}>
              <Button
                variant="outlined"
                size="large"
                color="success"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};
