import axios from "axios";

let baseApi: any = process.env.REACT_APP_API_URL;

const headers = () => {
  const token = localStorage.getItem("JWT");
  if (!token || token == null) {
    return {
      "Content-Type": "application/json",
    };
  } else {
    const JWT: any = localStorage.getItem("JWT");
    const token: string = JSON.parse(JWT).access_token;

    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
};

export const _get = async (endUrl: any, params?: any) => {
  const url = baseApi + endUrl;
  const response = await axios({
    method: "GET",
    url: url,
    params: params,
    headers: headers(),
  });
  return response;
};

export const _post = async (endUrl: any, data: any, params?: any) => {
  const url = baseApi + endUrl;
  const response = await axios({
    method: "POST",
    url: url,
    data: data,
    params: params,
    headers: headers(),
  });
  return response;
};

export const _put = async (endUrl: any, data: any, params?: any) => {
  const url = baseApi + endUrl;
  const response = await axios({
    method: "PUT",
    url: url,
    params: params,
    data: data,
    headers: headers(),
  });
  return response;
};

export const _delete = async (endUrl: any, params?: any) => {
  const url = baseApi + endUrl;
  const response = await axios({
    method: "DELETE",
    url: url,
    params: params,
    headers: headers(),
  });
  return response;
};
