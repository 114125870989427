import  { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

interface notification {
  msg: string;
  variant: string;
}

const useNotification = () => {
  const [msgConfiguration, setMsgConfiguration] = useState<notification | any>(
    {}
  );
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (msgConfiguration?.msg) {
      let defaultVariant: any = "default";
      if (msgConfiguration.variant) {
        defaultVariant = msgConfiguration.variant;
      }
      enqueueSnackbar(msgConfiguration.msg, {
        autoHideDuration: 4000,
        variant: defaultVariant,
        preventDuplicate: true,
      });
    }
  }, [msgConfiguration]);
  return [msgConfiguration, setMsgConfiguration];
};
export default useNotification;
