import React from "react";
import { Cart } from "../../Components/Cart/Cart";
import { Header } from "../../Components/Header/Header";

export const CartPage = () => {
  return (
    <>
      <Header />
      <Cart />
    </>
  );
};
