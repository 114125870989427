import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProductById } from "../../../Services/ProductService";
import { prodcutType } from "../../../Types/ProductTypes";
import { Box, Button } from "@mui/material";
import { Typography, Grid, Rating } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { addCart } from "../../../Redux/Actions/CartActions/CartActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useNotification from "../../../Utils/CustomHooks/UseNotification";

export const Product = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [productData, setProductData] = useState<any | prodcutType>({});
  const [rating, setRating] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();

  useEffect(() => {
    getProduct();
    return () => {
      setProductData({});
    };
  }, []);

  const addproduct = (productData: prodcutType) => {
    const message = "item added";

    sendNotification({
      msg: message,
      variant: "success",
    });
    dispatch(addCart(productData));
  };

  const getProduct = () => {
    setLoading(true);
    const id = params.id;
    getProductById(id)
      .then(
        (res: any) => {
          setProductData(res.data);
          setRating(res.data.rating_rate);
          setLoading(false);
        },
        (err: any) => {
          console.log(err);
          setLoading(false);
        }
      )
      .catch((error) => {
        console.log(error);
        setLoading(false);
        throw error;
      });
  };

  const ProductById = () => {
    return (
      <>
        <Grid xs={12} item paddingLeft={5} paddingBottom={1}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          wrap="nowrap"
          xs={12}
          item
          gap={2}
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          margin={1}
          sx={{
            padding: 5,
          }}
        >
          <Grid item xs={12}>
            <Box
              component="img"
              src={productData.image}
              sx={{
                height: {
                  xs: 200,
                  sm: 300,
                  md: 400,
                  lg: 500,
                  xl: 600,
                },
                display: "block",
                overflow: "hidden",
                width: {
                  xs: 200,
                  sm: 300,
                  md: 400,
                  lg: 500,
                  xl: 600,
                },
                objectFit: "contain",
                pointerEvents: "none",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{productData.title}</Typography>
            <Typography variant="h4">Description</Typography>
            <Box
              sx={{
                // width: 200,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Rating value={rating} readOnly />
              <Box sx={{ ml: 2 }}>{productData.rating_count}</Box>
              <PeopleIcon />
            </Box>
            <Typography variant="subtitle2">
              {productData.description}
            </Typography>
            <Typography variant="h5">{productData.price}$</Typography>
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={(e) => addproduct(productData)}
            >
              Add To Cart
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const Loader = () => {
    return (
      <>
        <Grid
          container
          wrap="nowrap"
          xs={12}
          item
          gap={2}
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          margin={1}
          sx={{
            padding: 5,
          }}
        >
          <Grid item xs={12}>
            <Skeleton variant="rounded" height={"100%"} width={"100%"}>
              <Box
                component="img"
                src={productData.image}
                sx={{
                  height: 550,
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Skeleton>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded" sx={{ marginBottom: 2 }}>
              <Typography variant="h4">Description</Typography>
            </Skeleton>
            <Skeleton variant="rounded" sx={{ marginBottom: 2 }}>
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating value={5} readOnly />
                <Box sx={{ ml: 2 }}>50</Box>
                <PeopleIcon />
              </Box>
            </Skeleton>
            <Skeleton variant="rounded" sx={{ marginBottom: 2 }}>
              <Typography variant="subtitle2">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure
                quis, reprehenderit dolores tempore autem rem fugiat esse atque
                consequatur mollitia, omnis aperiam dolor ut molestias, modi
                ipsam! Ratione, voluptatem cumque?
              </Typography>
            </Skeleton>
            <Skeleton variant="rounded" sx={{ marginBottom: 2 }}>
              <Typography variant="h5">1000$</Typography>
            </Skeleton>
            <Skeleton variant="rounded" sx={{ marginBottom: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={(e) => addproduct(productData)}
              >
                Add To Cart
              </Button>
            </Skeleton>
          </Grid>
        </Grid>
      </>
    );
  };

  return loading ? <Loader /> : <ProductById />;
};
