import { prodcutType } from "../../../Types/ProductTypes";

export const getProduct = (product: Array<prodcutType>) => {
  return {
    type: "ADD_PRODUCT",
    payload: product,
  };
};

export const sortProduct = (sortBy: string) => {
  return {
    type: "SORT_CATAGORY",
    payload: sortBy,
  };
};
