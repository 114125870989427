import React from "react";
import { Provider } from "react-redux";
import Store from "../../Redux/Store/Store";
import { SnackbarProvider } from "notistack";

export const Wrapper = ({ children }: any) => {
  return (
    <Provider store={Store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        maxSnack={1}
      >
        {children}
      </SnackbarProvider>
    </Provider>
  );
};
