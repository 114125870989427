import React, { useEffect } from "react";

import { RouterConfig } from "./Navigation/RouterConfig";
import { useDispatch } from "react-redux";
import { saveJWT } from "./Redux/Actions/AuthActions/AuthActions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const jwt = localStorage.getItem("JWT");
    if (jwt) {
      dispatch(saveJWT(JSON.parse(jwt)));
    }
  }, []);

  return <RouterConfig />;
}

export default App;
