import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { login, registerUser } from "../../Services/AuthService";
import useNotification from "../../Utils/CustomHooks/UseNotification";
import { useDispatch } from "react-redux";
import { saveJWT } from "../../Redux/Actions/AuthActions/AuthActions";
import jwtDecode from "jwt-decode";
import CustomTabPanel, { a11yProps } from "../CommonComponents/CustomTabPanel";
interface Props {
  open: boolean;
  handleClose: any;
}

export const LoginModal = ({ open, handleClose }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [UserName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [Password, setPassword] = useState<string>("");
  const dispatch = useDispatch();
  const [msg, sendNotification] = useNotification();

  const [emailerror, setEmailerror] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [registerEmailError, setRegisterEmailError] = useState<boolean>(false);
  const [registerUserNameError, setRegisterUserNameError] = useState<boolean>(
    false
  );
  const [registerPasswordError, setRegisterPasswordError] = useState<boolean>(
    false
  );

  useEffect(() => {
    trueState();
  }, [open]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const register = (e: any) => {
    e.preventDefault();
    const data = {
      UserName: UserName,
      Email: email.toLowerCase(),
      Password: Password,
    };
    if (
      UserName.trim() !== "" &&
      email.trim() !== "" &&
      Password.trim() !== ""
    ) {
      registerUser(data)
        .then(
          (res) => {
            sendNotification({
              msg: `${res.data.UserName} has been successfully Registerd `,
              variant: "success",
            });
            handleClose();
            clearState();
          },
          (err) => {
            const message = err.response.data.message;
            sendNotification({
              msg: message,
              variant: "error",
            });
            if (message === "User with same email already exists!") {
              setRegisterEmailError(true);
            }
          }
        )
        .catch((error) => {
          console.log(error);
          clearState();
          throw error;
        });
    } else {
      if (UserName.trim() === "") {
        sendNotification({
          msg: "Please Enter Valid Username!",
          variant: "error",
        });
        setRegisterUserNameError(true);
      } else if (email.trim() === "") {
        sendNotification({
          msg: "Please Enter Valid Email!",
          variant: "error",
        });
        setRegisterEmailError(true);
      } else if (Password.trim() === "") {
        sendNotification({
          msg: "Please Enter Valid Password!",
          variant: "error",
        });
        setRegisterPasswordError(true);
      }
    }
  };

  const Login = (e: any) => {
    e.preventDefault();
    const data = {
      Email: email,
      Password: Password,
    };
    if (email.trim() !== "" && Password.trim() !== "") {
      login(data)
        .then(
          (res: any) => {
            const accessToken = res.data.access_token;
            var data: {
              Email: string;
              exp: number;
              iat: number;
              userName: string;
            } = jwtDecode(accessToken);
            sendNotification({
              msg: `Login successfull! Welcome back ${data.userName}`,
              variant: "success",
            });
            const jwt: object = res.data;
            dispatch(saveJWT(jwt));
            clearState();
            handleClose();
          },
          (err) => {
            const message = err.response.data.message;
            sendNotification({
              msg: message,
              variant: "error",
            });
            if (message === "Email or Password Incorrect") {
              setEmailerror(true);
              setPasswordError(true);
            } else if (message == "Password Incorrect") {
              setPasswordError(true);
            }
          }
        )
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } else {
      if (email.trim() == "") {
        sendNotification({
          msg: "Please fill your Email!",
          variant: "error",
        });
        setEmailerror(true);
      } else if (Password.trim() == "") {
        sendNotification({
          msg: "Please fill Password!",
          variant: "error",
        });
        setPasswordError(true);
      } else {
        sendNotification({
          msg: "Unknown error occured!",
          variant: "warning",
        });
      }
    }
  };

  const clearState = () => {
    setEmail("");
    setUserName("");
    setPassword("");
  };

  const trueState = () => {
    setEmailerror(false);
    setPasswordError(false);
    setRegisterEmailError(false);
    setRegisterPasswordError(false);
    setRegisterUserNameError(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        clearState();
        trueState();
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal_Login"
    >
      <>
        <Box className="login_signUp_Modal">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            alignSelf={"center"}
          >
            <Grid item>
              <Box className="tab_pannel">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  textColor="inherit"
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <Tab label="Login" {...a11yProps(0)} />
                  <Tab label="Register" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
            <Grid item className="maodalclose">
              <IconButton
                onClick={() => {
                  clearState();
                  handleClose();
                }}
                className="icon_close_bttn"
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Divider
              variant="fullWidth"
              className="modalDivider"
              sx={{ paddingRight: 0, color: "#0000" }}
            />
          </Grid>
          <CustomTabPanel value={value} index={0}>
            <div>
              <form data-testid="login-form" onSubmit={(e: any) => Login(e)}>
                <Grid xs={12} sx={{ padding: 2 }} item>
                  <Grid item>
                    <TextField
                      data-testid="Email"
                      required
                      fullWidth
                      label="Email"
                      type="email"
                      variant="standard"
                      id="Login_Input"
                      autoComplete="off"
                      value={email}
                      error={emailerror}
                      helperText={emailerror ? "Email Incorrect!" : ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailerror(false);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon color="inherit" />
                          </InputAdornment>
                       )
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                      <InputLabel htmlFor="standard-adornment-amount">
                        Password
                      </InputLabel>
                      <Input
                        data-testid="Password"
                        required
                        autoComplete="off"
                        id="Login_Password"
                        error={passwordError}
                        value={Password}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordError(false);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    color="success"
                    type="submit"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Login
                  </Button>
                </Grid>
              </form>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <form onSubmit={(e: any) => register(e)}>
              <Grid xs={12} sx={{ padding: 2 }} item>
                <Grid item>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <TextField
                      required
                      label="Username"
                      variant="standard"
                      value={UserName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setRegisterUserNameError(false);
                      }}
                      error={registerUserNameError}
                      helperText={
                        registerUserNameError
                          ? "Please enter valid username!"
                          : ""
                      }
                      id="Register_UserName"
                      autoComplete="off"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <TextField
                      required
                      label="Email"
                      id="RegisterEmail"
                      variant="standard"
                      type="email"
                      value={email}
                      autoComplete="off"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setRegisterEmailError(false);
                      }}
                      error={registerEmailError}
                      helperText={
                        registerEmailError ? "Please enter valid email!" : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">
                      Password
                    </InputLabel>
                    <Input
                      required
                      value={Password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setRegisterPasswordError(false);
                      }}
                      error={registerPasswordError}
                      id="RegisterPassword"
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid xs={12} sx={{ textAlign: "center" }} item>
                <Button
                  variant="outlined"
                  color="warning"
                  type="submit"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Register
                </Button>
              </Grid>
            </form>
          </CustomTabPanel>
        </Box>
      </>
    </Modal>
  );
};
