import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { HomePage } from "../Pages/HomePage/HomePage";

import { AllProductsPage } from "../Pages/ProductsPages/AllProductsPage";
import { ProductPage } from "../Pages/ProductsPages/ProductPage";
import { CartPage } from "../Pages/CartPage/CartPage";
import { ProfilePage } from "../Pages/ProfilePage/ProfilePage";

export const RouterConfig = (props: any) => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/product",
      element: <AllProductsPage />,
    },
    {
      path: "/product/:id",
      element: <ProductPage />,
    },
    {
      path: "/cart",
      element: <CartPage />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
  ]);
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
};
