import { combineReducers } from "@reduxjs/toolkit";
import handleCart from "./HandleCart/HandleCart";
import handleProduct from "./HandleProduct/HandleProduct";
import handleAuth from "./HandleAuth/HandleAuth";

const rootReducers = combineReducers({
  handleCart,
  handleProduct,
  handleAuth,
});

export default rootReducers;
