import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, CardActions, Skeleton } from "@mui/material";
import { getAllProduct } from "../../Services/ProductService";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import CircularProgress from "@mui/material/CircularProgress";
// import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Grid } from "@mui/material";
import { prodcutType } from "../../Types/ProductTypes";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../Redux/Actions/ProductActions/ProductActions";
import { Props } from "../../Interfaces/interface";

export const AllProducts = (props: Props) => {
  const navigate: NavigateFunction = useNavigate();

  const productData: Array<prodcutType> = useSelector(
    (data: any) => data.rootReducers.handleProduct
  );

  const handleClick = (id: number) => {
    navigate("/product/" + id);
  };

  const AllProduct = () => {
    return (
      <Grid
        container
        padding={4}
        gap={3}
        justifyContent={{ xs: "center", md: "flex-start" }}
        item
      >
        {productData.length ? (
          productData.map((data) => (
            <Card
              sx={{
                maxWidth: {
                  xs: 300,
                  sm: 300,
                  md: 300,
                  lg: 300,
                  xl: 300,
                },
              }}
              key={data.id}
            >
              <CardActionArea onClick={() => handleClick(data.id)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={data.image}
                  alt={data.title}
                  sx={{ objectFit: "contain", pointerEvents: "none" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ maxHeight: 25, overflow: "hidden" }}
                  >
                    {data.title}
                  </Typography>
                  <Rating name="read-only" value={data.rating_rate} readOnly />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ maxHeight: 40, overflow: "hidden" }}
                  >
                    {data.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Grid container gap={2} mr={2} justifyContent={"right"}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ maxHeight: 40, overflow: "hidden" }}
                    alignSelf={"center"}
                  >
                    Price:${data.price}
                  </Typography>
                </Grid>
              </CardActions>
            </Card>
          ))
        ) : (
          <Grid container justifyContent={"center"} xs={12} item>
            <Typography variant="h1" color="text.secondary">
              No Products available
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const Loader = () => {
    return (
      <Grid container padding={2} gap={3}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((data, index) => (
          <Card sx={{ maxWidth: 345 }} key={index}>
            <>
              <Box component="div" height="140" width={400}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={140}
                ></Skeleton>
              </Box>
              <CardContent>
                <Skeleton animation="wave" variant="rounded">
                  <Rating name="read-only" value={1} readOnly />
                </Skeleton>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={{ marginTop: 1 }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ maxHeight: 40, overflow: "hidden" }}
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ducimus neque expedita enim illo officiis quae laborum
                    nostrum commodi sed consequatur veritatis sit voluptates
                    obcaecati debitis facilis modi impedit, nemo quidem.
                  </Typography>
                </Skeleton>
              </CardContent>
            </>
            <CardActions>
              <Grid container gap={2} mr={2} justifyContent={"right"}></Grid>
            </CardActions>
          </Card>
        ))}
      </Grid>
    );
  };

  return props.loading ? <Loader /> : <AllProduct />;
};
