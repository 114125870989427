import { prodcutType } from "../../../Types/ProductTypes";

let Products: Array<prodcutType> = [];
let tempProducts: Array<prodcutType> = [];

const handleProduct = (state: Array<prodcutType> = Products, action: any) => {
  const data = action.payload;
  switch (action.type) {
    case "ADD_PRODUCT":
      state = data;
      Products = state;
      tempProducts = state;
      return [...state];
    case "SORT_CATAGORY":
      tempProducts = Products;
      state = Products;
      if (data !== 5) {
        state = tempProducts.filter(
          (data: any) => data.category === action.payload
        );
        return state;
      } else {
        return Products;
      }

    default:
      return state;
  }
};

export default handleProduct;
