export const saveJWT = (jwt: object) => {
  return {
    type: "SAVE_JWT",
    payload: jwt,
  };
};

export const deleteJWT = () => {
  return {
    type: "REMOVE_JWT",
    payload: {},
  };
};
